<template>
  <div class="ele-body">
    <el-tabs >
            <el-card shadow="never">
                <el-form ref="form" :model="editForm" label-width="150px">
                    <div class="innerBox">
                        <div class="innerTitle">
                            <i class="el-icon-location" style="color:rgb(51,204,153);font-size:20px"></i>
                             阶段一：
                        </div>
                        <el-form-item label="目的：">
                            <el-input v-model="editForm.purpose" class="inputWidth"></el-input>
                        </el-form-item>
                        <el-form-item label="添加红包优惠：">
                            <el-input v-model="editForm.red" class="inputWidth"></el-input>
                        </el-form-item>
                        <el-form-item label="新用户送零钱：">
                            <el-input v-model="editForm.bonus" class="inputWidth"></el-input>
                        </el-form-item>
                        <el-form-item label="佣金设置：">
                            <el-input v-model="editForm.commission" class="inputWidth"></el-input>
                        </el-form-item>
                        <el-form-item label="预计拓客数：">
                            <el-input v-model="editForm.num" class="inputWidth"></el-input>
                        </el-form-item>
                        <el-form-item label="预计活动投入资金：">
                            <el-input v-model="editForm.price" class="inputWidth"></el-input>
                        </el-form-item>
                    </div>
                    <div class="innerBox">
                        <div class="innerTitle">
                            <i class="el-icon-location" style="color:rgb(51,204,153);font-size:20px"></i>
                             阶段二：
                        </div>
                      <el-form-item label="目的：">
                        <el-input v-model="editForm.purpose1" class="inputWidth"></el-input>
                      </el-form-item>
                      <el-form-item label="添加红包优惠：">
                        <el-input v-model="editForm.red1" class="inputWidth"></el-input>
                      </el-form-item>
                      <el-form-item label="新用户送零钱：">
                        <el-input v-model="editForm.bonus1" class="inputWidth"></el-input>
                      </el-form-item>
                      <el-form-item label="佣金设置：">
                        <el-input v-model="editForm.commission1" class="inputWidth"></el-input>
                      </el-form-item>
                      <el-form-item label="预计拓客数：">
                        <el-input v-model="editForm.num1" class="inputWidth"></el-input>
                      </el-form-item>
                      <el-form-item label="预计活动投入资金：">
                        <el-input v-model="editForm.price1" class="inputWidth"></el-input>
                      </el-form-item>
                    </div>
                    <div class="innerBox">
                        <div class="innerTitle">
                            <i class="el-icon-location" style="color:rgb(51,204,153);font-size:20px"></i>
                             阶段三：
                        </div>
                      <el-form-item label="目的：">
                        <el-input v-model="editForm.purpose2" class="inputWidth"></el-input>
                      </el-form-item>
                      <el-form-item label="添加红包优惠：">
                        <el-input v-model="editForm.red2" class="inputWidth"></el-input>
                      </el-form-item>
                      <el-form-item label="新用户送零钱：">
                        <el-input v-model="editForm.bonus2" class="inputWidth"></el-input>
                      </el-form-item>
                      <el-form-item label="佣金设置：">
                        <el-input v-model="editForm.commission2" class="inputWidth"></el-input>
                      </el-form-item>
                      <el-form-item label="预计拓客数：">
                        <el-input v-model="editForm.num2" class="inputWidth"></el-input>
                      </el-form-item>
                      <el-form-item label="预计活动投入资金：">
                        <el-input v-model="editForm.price2" class="inputWidth"></el-input>
                      </el-form-item>
                    </div>
                    <div class="innerBoxTo">
                        <el-form-item style="float:right;">
                            <el-button type="primary" @click="save" v-if="permission.includes('sys:template:edit')">确认提交</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </el-card>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "SysUser",
    data() {
        return {
            editForm: {},  // 表单数据
        }
    },
    created(){
      const loading = this.$loading({lock: true});
      this.$http.get('/finance/activity_template').then(res=>{
        loading.close()
        let data=res.data
        this.editForm=data.data
      })
    },

    computed: {
        ...mapGetters(["permission"]),
    },
    mounted() {


    },
  methods:{
    save(){
      const loading = this.$loading({lock: true});
      this.$http.post('/finance/activity_template',this.editForm).then(res=>{
        loading.close()
        let data=res.data
        if(data.code === 0){
          this.$message({type: 'success', message:data.msg});
        }else{
          this.$message.error(data.msg);
        }
      }).catch(e=>{
        loading.close();
        this.$message.error(e.message);
      })
    },

  }
}
</script>

<style scoped>
    .ele-block .el-upload-dragger {
        width: 100%;
    }
    .changeStyle{
        width:150px;margin-right:30px;
    }
    .ele-body{
        padding:15px;
    }
    .el-upload-list el-upload-list--text{display:none !important}
    /deep/.el-tabs__item{
        padding:0 20px !important;
        font-size: 15px;
    }
    /deep/.el-tabs__nav-scroll{
        background: white !important;
        padding-top: 10px;
    }
    .drop-down-first{
        width:150px;
        margin-right:10px;
    }
    .drop-down-second{
       width:150px; 
    }
    .search-margin{
        margin-left: 10px;
    }
    .boxTitle{padding:8px}
    .orderImgBox{margin-left: 10px;height: 80px;}
    .orderImgBox span{
        font-size: 36px;
    }
    .user-info-tabs >>> .el-tabs__nav-wrap {
        padding-left: 20px;
    }
    .innerBox{
        width:40%;
        margin-left:100px;
        padding-top: 20px;
        border-bottom: 1px rgb(240,240,240) solid;
    }
    .innerBoxTo{
        width:40%;
        margin-left:100px;
        padding-top: 20px;
    }
    .innerTitle{
        font-size: 16px;
        margin-bottom: 20px;
        margin-left: 13px;
    }
    .inputWidth{
        width:100%;
    }
    /deep/.el-dialog .el-dialog__body{
        background-color: white !important;
        padding-left: 10%;
    }
    /deep/.el-dialog__header{
        border-bottom:none;
    }
    /deep/.el-dialog__footer{
        border-top: none;
    }
</style>

